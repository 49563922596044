#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 150px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 150px;
}

.search-input {
  border: 1px solid #28a745; /* Add your desired border color and thickness */
  border-right: none; /* Remove the right border to blend with the button */
  border-radius: 5px 0 0 5px; /* Rounded corners for the left side */
}

.input-group-append .btn {
  border-radius: 0 5px 5px 0; /* Rounded corners for the right side of the button */
}
/* ==========================================home========================================== */
/* Add these styles to your existing App.css */

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination .page-item {
  margin: 0 5px;
}

.pagination .page-link {
  padding: 10px 15px;
  border: 1px solid #007bff;
  border-radius: 5px;
  color: #007bff;
  text-decoration: none;
}

.pagination .page-link:hover {
  background-color: #007bff;
  color: #fff;
}

.pagination .active .page-link {
  background-color: #007bff;
  color: #fff;
}

.card {
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05); /* Zoom in effect */
}



/* ============================sidebar===================================================== */


*{
  margin:0;
  padding:0;
  text-decoration: none;
  /* background-color: rgb(255, 114, 114); */
}
body{
  background-color: rgb(224, 223, 223);
}
.containerA{
  display:flex;
}
main{
  width: 100%;
  padding: 20px;
 

}
.sidebar{
  background: #11b4ff8e;
  color: #000000;
  height: 100dvh;
  width: 200px;
  transition: all 0.5s;
}
.top_section{
  display: flex;
  align-items:center;
  padding:20px 15px;
}
.logo{
  font-size: 30px;
}
.bars{
  display: flex;
  font-size: 24px;
  margin-left: 10px;
}
.link{
  display: flex;
  color: #000000ee;
  padding: 10px 15px;
  font-family: poppine;
  gap: 15px;
  font-size:20px;
  font-weight: 800;
  transition: all 0.5s;
  word-spacing: 3px;
}
.link:hover{
  background:#0602ff4d;
  color: #000;
  transition: all 0.5s;
}
.active{
  background: #0602ff9d;
  color: #000;
}
.icon, .link_text{
  font-size: 18px;
}

/* ===================================:checked box========================================== */
.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox .checkmark {
  position: relative;
  width: 16px;
  height: 16px;
  background-color: #eee;
  border-radius: 4px;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: #333b69;
}

.custom-checkbox .checkmark::after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid rgb(0, 26, 255);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark::after {
  display: block;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  border-width: .3em;
}








.bottombutton:hover {
  background-color: #314eee;
  border-bottom-left-radius: 7px;  /* Adjust these values as needed */
  border-bottom-right-radius: 7px; /* Adjust these values as needed */
}


/* box-shadow: '0 4px 8px rgba(0, 0, 0, 0.1)' */
/* ==================================openstore========================= */
/* .hero-section {
  background-image: "url(/bb.png)";
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.navbar {
  position: relative;
  z-index: 10;
  background-color: transparent !important;
} */
/* =============================================Footer================================= */
.footer {
  background-color: #ddd;
  padding: 40px 0;
}

.footer hr {
  border-top: 0.5px solid #CACACE;
  width: 80%;
  margin: 0 auto 30px auto;
}

.footer .row {
  justify-content: center;
}

.footer .col-md-2 {
  text-align: left;
}

.footer .col-md-2 a {
  color: #000000;
  text-decoration: none;
  display: block;
  font-family: poppine;
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.footer .col-md-2 p {
  margin: 0;
  font-size: 0.95rem;
  font-family: poppine;
  font-weight: bold;
  color: #585858;
}

.email-form {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.email-form input {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 100%;
  font-family: poppine;
  max-width: 500px;
}

.email-form button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.email-form button:hover {
  background-color: #0056b3;
}

.social-icon {
  color: #6c757d;
  font-size: 1.5rem;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #007bff;
}


/* ========================================login============================ */
/* App.css or a dedicated CSS file */

/* App.css or a dedicated CSS file */

.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa; /* Optional: Background color */
}

.login-card {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.input-field {
  border-radius: 5px;
}

.password-field {
  display: flex;
  align-items: center;
}

.toggle-password-btn {
  border: none;
  background: transparent;
  margin-left: -35px; /* Adjust position as needed */
  cursor: pointer;
}

.btn-custom {
  background-color: #007bff;
  border: none;
  color: white;
}

.btn-custom:hover {
  background-color: #0056b3;
}
/* ===============================search bar style============================*/
.search-results {
  position: absolute;
  width: 30%; /* Same as the search input width */
  max-height: 100%; /* Adjust the max height as needed */
  overflow-y: auto;
  background: white;
  border: 1px solid #ccc;
  z-index: 1000; /* Ensure it appears above other elements */
  margin-top: 2px;
  margin-left: 20px;
  border-radius: 4px;
}

.search-results .list-group-item {
  font-family: "Poppins";
  cursor: pointer;
  padding-left: 10px;
 
}
/* ==========================================navbar======================================= */
/* Styles for mobile view */
/* Styles for mobile view */
@media (max-width: 700px) {
  .navbar .navbar-brand {
    width: 80%;
    
    /* text-align: center; */
  }

  .navbar-toggler {
    position: absolute;
    right: 10px;
    
    

    top: 15px;
  }

  .navbar .form-inline {
    width: 100%;
    

    margin-top: 10px;
  }

  .navbar .form-inline .input-group {
    width: 100%;
    

  }

  .navbar .input-group .form-control {
    width: 100%;
    

  }
}

/* =======================================imprint========================= */
.container {
  width: 80%;
  margin: 0 auto;
  padding: 0px;
}
/* ========================================== coupon==========================*/
.coupon-container {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); Responsive columns */
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  padding: 10px;
}

.coupon-card {
  width: 100%; /* Ensures it takes up the full width of the grid column */
  height: 350px; /* Fixed height for uniformity */
  border: 1.5px solid #414141;
  border-radius: 8px;
  text-align: center;
  position: relative;
  box-sizing: border-box; /* Ensures padding doesn’t affect the width/height */
  background-color: #f0f0f0; /* Optional: Add a background color */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures content is spaced evenly */
}

.image-wrapper {
  height: 200px; /* Fixed height for the image area */
  overflow: hidden; /* Ensures that the images do not overflow */
  position: relative; /* Needed for absolute positioning of the store image */
}

.coupon-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire area without distortion */
  border-radius: 8px;
}

.store-image {
  width: 90px;
  height: 55px;
  background-color: white;
  position: absolute;
  bottom: 0; /* Adjust the positioning as needed */
  left: 0; /* Adjust the positioning as needed */
  border: 1px solid white;
  object-fit:contain; /* Ensures the store image is properly contained */
}

h3 {
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
}

p {
  font-size: 14px;
  margin: 5px 0;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .coupon-container {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust columns for medium screens */
  }

  .coupon-card {
    height: auto; /* Allow height to adjust based on content */
  }
}

@media (max-width: 768px) {
  .coupon-container {
    grid-template-columns: 1fr; /* Single column layout for small screens */
  }

  .coupon-card {
    height: auto; /* Allow height to adjust based on content */
  }

  .store-image {
    width: 80px; /* Smaller size for small screens */
    height: 55px;
  }
}

@media (max-width: 480px) {
  .coupon-card {
    height: auto; /* Allow height to adjust based on content */
  }

  .store-image {
    width: 60px; /* Smaller size for very small screens */
    height: 45px;
  }
}






/* App.css or another stylesheet */

/* For mobile devices */
@media (max-width: 576px) {
  .hero-section {
    background-size: cover;
    background-position: center;
    min-height: auto;
  }

  .navbar-brand img {
    height: 50px;
  }

  .search-input {
    width: 100%;
  }

  .search-results {
    font-size: 0.9rem;
  }

  .swiper-slide img {
    width: 100%;
    height: auto;
  }
}

